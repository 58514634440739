import React, { FC } from 'react';
import { SupportLink } from '@/components/common/SupportLink';
import { FloatIcons } from '@/components/landing/FloatIcons';
import { useGetSupportSocialsQuery } from '@/controllers/graphql/generated';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';

interface Props {
  shouldShowScrollToTop?: boolean;
}

export const SupportSocialMedias: FC<Props> = ({ shouldShowScrollToTop }) => {
  const { subDomain } = useSubDomainContext();
  const { data } = useGetSupportSocialsQuery({
    variables: { domain: subDomain },
  });

  return (
    <FloatIcons shouldShowScrollToTop={shouldShowScrollToTop}>
      {data?.getSupportSocials.map((getSupportSocial) => (
        <SupportLink
          key={getSupportSocial.source}
          providerName={getSupportSocial.name}
          href={getSupportSocial.source}
        />
      ))}
    </FloatIcons>
  );
};
